
	import { Component, Vue, Watch } from "vue-property-decorator";
	import request from "@/request";
	import { ECharts, EChartsOption, init } from "echarts";
	// @ts-ignore
	@Component({})
		export default class floorDom extends Vue {
            isDrawBack = true;
            colors = ["#4261ED", "#EF9F24", "#c4ccD6", "#FD4848"];
            btnList = [
                {
                    name: "维修",
                    value: "1"
                },
                {
                    name: "维保",
                    value: "2"
                }
            ];
            textList = ["运行中", "故障", "报警", "离线"];
            activeBtn = 1;
            list1 = [
                {
                    img: require("../assets/meau/jidian2.png"),
                    text: "空调报警",
                    number: 22,
                    number2: 40
                },
                {
                    img: require("../assets/meau/2co.png"),
                    text: "气体报警",
                    number: 9,
                    number2: 60
                }
            ];
            list2 = [
                {
                    name: "室内机",
                    number1: 198,
                    number2: 5,
                    number3: 6,
                    number4: 0,
                    img: require("../assets/icon/home_1.png")
                },
                {
                    name: "室外机",
                    number1: 21,
                    number2: 1,
                    number3: 2,
                    number4: 0,
                    img: require("../assets/icon/home_2.png")
                },
                {
                    name: "CO",
                    number1: 11,
                    img: require("../assets/icon/home_co.png"),
                    number2: 0,
                    number3: 0,
                    number4: 0
                },
                {
                    name: "CO2",
                    number1: 9,
                    img: require("../assets/icon/home_co2.png"),
                    number2: 0,
                    number3: 0,
                    number4: 0
                },
                {
                    name: "水表",
                    number1: 40,
                    img: require("../assets/icon/home_shui.png"),
                    number2: 0,
                    number3: 0,
                    number4: 0
                },
                {
                    name: "电表",
                    number1: 50,
                    img: require("../assets/icon/home_dian.png"),
                    number2: 0,
                    number3: 0,
                    number4: 0
                },
                {
                    name: "监控",
                    number1: 521,
                    img: require("../assets/icon/home_she.png"),
                    number2: 2,
                    number3: 6,
                    number4: 3
                }
            ]
        setChart (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                legend: {
                    top: "5%",
                    left: "center",
                    show: false
                },
                series: [
                    {
                    // name: "Access From",
                    type: "pie",
                    radius: ["70%", "88%"],
                    center: ["50%", "40%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: "center"
                    },
                    itemStyle: {
                        // borderRadius: 10,
                        borderColor: "#ffffff",
                        borderWidth: 5
                    },
                    emphasis: {
                        label: {
                        show: false,
                            fontSize: 40,
                            fontWeight: "bold"
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 22, name: "机电报警" },
                        { value: 9, name: "气体报警" }
                    ]
                    }
                ],
                color: this.colors
            };
			chart.setOption(myOption);
		}
        setChart2 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                // title: {
                //     text: "近七天",
                //     textStyle:{
                //     }
                // },
                legend: {
                    show: true,
                    data: ["新建", "维修", "已结束"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1",
                        fontSize: "14px"
                    }
                },
                grid: {
                    top: "15%",
					left: "5%",
					right: "2%",
					bottom: "4%",
					containLabel: true
				},
                xAxis: {
                    type: "category",
                    data: ["8-1", "8-2", "8-3", "8-4", "8-5", "8-6", "8-7"],
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: false
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					}
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                            color: ["#e0e2e3"]
                        }
					},
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
                },
                series: [
                    {
                        name: "新建",
                        data: [120, 180, 180, 80, 70, 110, 130],
                        type: "bar",
                        barGap: 0
                    },
                    {
                        name: "维修",
                        data: [100, 150, 160, 70, 60, 50, 100],
                        barGap: 0,
                        type: "bar"
                    },
                    {
                        name: "已结束",
                        data: [50, 80, 130, 60, 10, 60, 30],
                        type: "bar"
                    }
                ],
                color: this.colors
            };
			chart.setOption(myOption);
		}
        setChart3 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                legend: {
                    data: ["今日", "昨日"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1"
                    },
                    top: 30
                },
				grid: {
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["7:12", "7:13", "7:14", "7:15", "7:16", "7:17", "7:18"]
				},
				yAxis: {
					type: "value",
                    nameTextStyle: {
						fontSize: 10,
						align: "center"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
                tooltip: {
                    trigger: "axis"
                },
				series: [
					{
						name: "今日",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [100, 120, 150, 160, 80, 200, 200]
					},
                    {
						name: "昨日",
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: [120, 132, 101, 134, 90, 230, 210],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				],
                color: this.colors
			};
			chart.setOption(myOption);
		}
		mounted () {
            this.$nextTick(() => {
                this.setChart("home_chart1");
                this.setChart2("home_chart2");
                this.setChart3("home_chart3");
                this.setChart3("home_chart4");
            });
		}
        @Watch("isDrawBack")
        onisDrawBackchange (val) {
            this.$store.commit("changeDraw", val);
        }
        @Watch("$store.state.unityEnd")
        onunityEndchange (val) {
            this.isDrawBack = false;
        }
	}
